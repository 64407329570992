<template>
  <v-main>
    <div class="fill-height md-0 pd-0" fluid>
      <v-row align="center" v-if="existeURLStr">
        <v-col class="m-0" style="border: 0px solid #ff0000">
          <v-row>
            <v-col cols="12" lg="4" style="border: 0px solid #ff0000">
              <div>
                <v-row>
                  <v-col cols="12">
                    <v-card class="elevation-0" v-if="tipoForm == 0">
                      <v-row>
                        <v-col align="center" class="mt-8">
                          <img :src="logo" width="200" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <v-form @submit.prevent="login">
                            <v-toolbar class="text-center" flat>
                              <v-spacer></v-spacer>
                              <v-toolbar-title class="font-weight-bold primary--text"
                                >Ingreso Plataforma</v-toolbar-title
                              >
                              <v-spacer></v-spacer>
                            </v-toolbar>

                            <v-card-text>
                              <v-text-field
                                label="E-Mail"
                                class="font-weight-light"
                                v-if="this.usuario.tipo == 0 || this.usuario.tipo == 1"
                                name="E-Mail"
                                outlined
                                prepend-inner-icon="mdi-account"
                                type="text"
                                color="primary"
                                v-model="usuario.email"
                              ></v-text-field>

                              <v-text-field
                                id="password"
                                class="font-weight-light"
                                label="Clave"
                                v-if="usuario.tipo == 0 || usuario.tipo == 3"
                                outlined
                                name="password"
                                prepend-inner-icon="mdi-lock"
                                color="primary"
                                :append-icon="value ? 'visibility' : 'visibility_off'"
                                @click:append="() => (value = !value)"
                                :type="value ? 'password' : 'text'"
                                v-model="usuario.pass"
                              ></v-text-field>
                              <v-text-field
                                id="password2"
                                class="font-weight-light"
                                label="Repetir Clave"
                                v-if="usuario.tipo == 3"
                                outlined
                                name="password2"
                                prepend-inner-icon="mdi-lock"
                                color="primary"
                                type="password"
                                v-model="usuario.pass2"
                              ></v-text-field>

                              <v-text-field
                                label="Código de Verificación"
                                class="font-weight-light"
                                name="Codigo"
                                v-if="usuario.tipo == 2"
                                outlined
                                prepend-inner-icon="mdi-account"
                                type="text"
                                color="primary"
                                v-model="usuario.codigo"
                              ></v-text-field>
                              <v-spacer></v-spacer>
                              <div v-if="mensaje !== ''" align="center">
                                <p class="red--text font-weight-light">
                                  {{ mensaje }}
                                </p>
                              </div>
                              <div v-if="mensajeExito !== ''" align="center">
                                <p class="green--text font-weight-light">
                                  {{ mensajeExito }}
                                </p>
                              </div>
                              <v-spacer></v-spacer>
                              <div>
                                <a
                                  class="primary--text font-weight-light"
                                  @click="olvideClave"
                                  >{{ textoOlvideClave }}</a
                                >
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                class="md-12 font-weight-light"
                                color="primary"
                                dark
                                block
                                type="submit"
                                >{{ textoBtn }}</v-btn
                              >
                            </v-card-actions>
                          </v-form>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </div>
            </v-col>

            <v-col cols="8" v-if="!$vuetify.breakpoint.mobile" class="opacity">
              <v-row align="center" justify="center">
                <v-col class="text-center" cols="12">
                  <h1 class="display-1 font-weight-thin mb-4"></h1>
                  <h4 class="subheading">
                    <p class=""></p>
                    <h6></h6>
                  </h4>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row align="center" v-else>
        <!--<v-col cols="12"> {{ dataErrorURL }} </v-col>-->
        <v-col align="center" class="m-0" style="border: 0px solid #ff0000">
          <v-row align="center">
            <v-col align="center" cols="12" lg="12" style="border: 0px solid #ff0000">
              <div align="center" style="border: 0px solid #ff0000; width: 400px">
                <v-alert v-model="alert" border="left" :color="colorerror" dark>
                  {{ dataErrorURL }}
                </v-alert>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-main>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import router from "../../router";

export default {
  data() {
    return {
      usuario: {
        email: "",
        pass: "",
        hash:
          Math.random().toString(36).substring(2, 15) +
          Math.random().toString(36).substring(2, 15),
        codigo: "",
        pass2: "",
        tipo: 0,
      },
      mensaje: "",
      mensajeExito: "",
      tipoForm: 0,
      textoOlvideClave: "Olvide mi clave",
      textoBtn: "Ingresar",
      background: "/assets/city.png",
      value: String,
      url: "",
      existeURLStr: true,
      dataErrorURL: "Comprobando comunidad, espere un momento",
      colorerror: "orange",
      logo: require("@/assets/imagenes/logo.png"),
    };
  },
  methods: {
    ...mapMutations(["obtenerUsuario", "traeComunidad"]),
    ...mapActions([
      "guardarUsuario",
      "leerToken",
      "cerrarSesion",
      "traerComunidades",
      "AlmacenaUsuario",
      "registraEmpresa",
    ]),
    login() {
      let URL = () => {
        switch (this.usuario.tipo) {
          case 0:
            return "/login";
          case 1:
            return "/login/recuperarClave";
          case 2:
            return "/login/verificaCode";
          case 3:
            return "/login/cambiapw";
        }
      };

      var aceptaSend = true;

      if (this.usuario.tipo == 3 && this.usuario.pass != this.usuario.pass2) {
        aceptaSend = false;
        this.mensaje = "Las claves no coinciden, intentelo nuevamente";
      }

      if (aceptaSend) {
        this.axios
          .post(URL(), this.usuario)
          .then((res) => {
            if (this.usuario.tipo == 0) {
              const token = res.data.token;

              //this.traerComunidades(token);
              //this.$store.dispatch("traerComunidades", token);
              //this.guardarUsuario(token);

              //this.registraEmpresa(this.$route.params.empresa);
              this.AlmacenaUsuario(token);
            } else if (this.usuario.tipo == 1) {
              this.usuario.pass = "";
              this.usuario.pass2 = "";
              (this.usuario.tipo = 2), (this.mensaje = "");
              this.textoBtn = "Validar Código";
              console.log("es 1");
            } else if (this.usuario.tipo == 2) {
              this.usuario.pass = "";
              this.usuario.pass2 = "";
              this.usuario.tipo = 3;
              this.mensaje = "";
              this.textoBtn = "Cambiar clave";
              console.log("es 2");
            } else if (this.usuario.tipo == 3) {
              this.usuario.email = "";
              this.usuario.pass = "";
              this.usuario.pass2 = "";
              this.usuario.codigo = "";
              this.mensaje = "";
              this.mensajeExito = "Cambio Exitoso!";
              this.usuario.tipo = 0;
              this.textoBtn = "Ingresar";
              console.log("es 3");
            }
          })
          .catch((err) => {
            this.mensaje = err.response.data.mensaje;
          });
      }
      console.log(URL());
    },
    olvideClave() {
      if (this.usuario.tipo == 0) {
        this.usuario.tipo = 1;
        this.textoOlvideClave = "Cancelar recuperar clave";
        this.textoBtn = "Recuperar Clave";
      } else {
        this.usuario.tipo = 0;
        this.textoOlvideClave = "Olvide mi clave";
        this.textoBtn = "Ingresar";
      }
    },
    recuperarClave() {
      this.axios
        .post("/login/recuperarClave", this.usuario)
        .then((res) => {
          this.usuario.pass = "";
          this.usuario.pass2 = "";
          this.tipoForm = 2;
        })
        .catch((err) => {
          this.mensajeRecuperacion = err.response.data.mensaje;
        });
    },
    verificaCode() {
      this.axios
        .post("/login/verificaCode", this.usuario)
        .then((res) => {
          this.usuario.pass = "";
          this.usuario.pass2 = "";
          this.tipoForm = 3;
        })
        .catch((err) => {
          this.mensajeCodigo = err.response.data.mensaje;
        });
    },
    changepwd() {
      if (this.usuario.pass != this.usuario.pass2) {
        this.mensajeClaves = "Las claves no coinciden";
      } else {
        this.axios
          .post("/login/cambiapw", this.usuario)
          .then((res) => {
            this.usuario.email = "";
            this.usuario.pass = "";
            this.usuario.pass2 = "";
            this.usuario.codigo = "";
            this.tipoForm = 0;
          })
          .catch((err) => {
            this.mensajeClaves = err.response.data.mensaje;
          });
      }
    },

    regresar() {
      this.tipoForm = 0;
    },
  },
  created() {
    //this.$store.commit('SET_LAYOUT', 'login-layout')
    this.url = this.$route.params.empresa;
    //this.

    //console.log(this.$route.params.empresa);
    //console.log("a100.dfweèé".normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
    //console.log("Losnogales 4 S.A".match(/[a-z0-9]+/gi));
  },
  mounted() {
    //this.existeURL(this.$route.params.empresa);
  },
};
</script>

<style>
.backgroundTop {
  background: rgb(31, 139, 247);
  background: -moz-linear-gradient(
    351deg,
    rgba(31, 139, 247, 1) 0%,
    rgba(50, 88, 244, 1) 100%
  );
  background: -webkit-linear-gradient(
    351deg,
    rgba(31, 139, 247, 1) 0%,
    rgba(50, 88, 244, 1) 100%
  );
  background: linear-gradient(
    351deg,
    rgba(31, 139, 247, 1) 0%,
    rgba(50, 88, 244, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1f8bf7", endColorstr="#3258f4", GradientType=1);
}

.opacity {
  opacity: 0.3;
}

.backgroundDinamico {
  background: linear-gradient(167deg, #5c6bc0, #3f1260, #009688, #4dd0e1);
  background-size: 400% 400%;

  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 11% 0%;
  }

  50% {
    background-position: 90% 100%;
  }

  100% {
    background-position: 11% 0%;
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 11% 0%;
  }

  50% {
    background-position: 90% 100%;
  }

  100% {
    background-position: 11% 0%;
  }
}

@keyframes AnimationName {
  0% {
    background-position: 11% 0%;
  }

  50% {
    background-position: 90% 100%;
  }

  100% {
    background-position: 11% 0%;
  }
}
</style>
