import Vue from 'vue'
import Vuex from 'vuex'

import router from '../router'

// para decodificar el jwt
import decode from 'jwt-decode'
import '../plugins/axios'

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('token') || '',
    usuarioDB: '',
    layout: 'login-layout',
    itemsMenu: [],
    imageSrc: '',
    notificationSystem: {
      options: {
        show: {
          theme: "dark",
          icon: "icon-person",
          position: "topCenter",
          progressBarColor: "rgb(0, 255, 184)",
          buttons: [
            [
              "<button>Ok</button>",
              function(instance, toast) {
                alert("Hello world!");
              },
              true
            ],
            [
              "<button>Close</button>",
              function(instance, toast) {
                instance.hide(
                  {
                    transitionOut: "fadeOutUp",
                    onClosing: function(instance, toast, closedBy) {
                      console.info("closedBy: " + closedBy);
                    }
                  },
                  toast,
                  "buttonName"
                );
              }
            ]
          ],
          onOpening: function(instance, toast) {
            console.info("callback abriu!");
          },
          onClosing: function(instance, toast, closedBy) {
            console.info("closedBy: " + closedBy);
          }
        },
        ballon: {
          balloon: true,
          position: "bottomCenter"
        },
        info: {
          position: "bottomLeft"
        },
        success: {
          position: "bottomRight"
        },
        warning: {
          position: "topLeft"
        },
        error: {
          position: "topRight"
        },
        question: {
          timeout: 20000,
          close: false,
          overlay: true,
          toastOnce: true,
          id: "question",
          zindex: 999,
          position: "center",
          buttons: [
            [
              "<button><b>YES</b></button>",
              function(instance, toast) {
                instance.hide({ transitionOut: "fadeOut" }, toast, "button");
              },
              true
            ],
            [
              "<button>NO</button>",
              function(instance, toast) {
                instance.hide({ transitionOut: "fadeOut" }, toast, "button");
              }
            ]
          ],
          onClosing: function(instance, toast, closedBy) {
            console.info("Closing | closedBy: " + closedBy);
          },
          onClosed: function(instance, toast, closedBy) {
            console.info("Closed | closedBy: " + closedBy);
          }
        }
      }
    
    
    },
    
  },
  mutations: {
    
    
    obtenerUsuario(state, payload){
      state.token = payload;
      if(payload === ''){
        state.usuarioDB = ''
      }else{
        state.usuarioDB = decode(payload);
      }
    },
    Menu(state){
      var mods = state.usuarioDB.data.mods;

      //console.log(state.usuarioDB.data)
      state.itemsMenu = []





            var ArrayDashboard = [1]
            if (ArrayDashboard.some(md => mods.includes(md)) || state.usuarioDB.data.role == 'ADMIN') {
              let estructura2 = {
                id:9000,
                sub: 'Dashboard',
                name: 'Dashboard',
                children: []
              }

              if (mods.includes(1) || state.usuarioDB.data.role == 'ADMIN') {
                  estructura2.children.push({
                      id:1,
                      name: 'Dashboard',
                      link: {name: 'dashboard'},
                      icon: 'mdi-view-dashboard',
                  })
              }

              state.itemsMenu.push(estructura2)

            }


            var ArrayGestion = [3,4,5,6,7,8,9]
            if (ArrayGestion.some(md => mods.includes(md)) || state.usuarioDB.data.role == 'ADMIN') {
              let estructura2 = {
                id:9002,
                sub: 'Gestión',
                name: 'Gestión',
                children: []
              }

              if (mods.includes(4) || state.usuarioDB.data.role == 'ADMIN') {
                estructura2.children.push({
                    id:4,
                    icon: 'mdi-briefcase-account',
                    name: 'Mandantes',
                    link: {name: 'mandantes'},
                })
              }
              if (mods.includes(5) || state.usuarioDB.data.role == 'ADMIN') {
                estructura2.children.push({
                    id:5,
                    icon: 'mdi-text-box-multiple',
                    name: 'Presupuestos',
                    link: {name: 'presupuestos'},
                })
              }

              let estructura3 = {
                id:8000,
                name: 'Proyectos',
                icon: 'mdi-briefcase',
                grupo: 'proyectos',
                children: []
              }

              if (mods.includes(6) || state.usuarioDB.data.role == 'ADMIN') {
                  estructura3.children.push({
                      id:6,
                      name: 'Proyectos',
                      isChecked: true,
                      //link: '/principal/finanzas/cuentas'
                      link: {name: 'proyectos'}
                  })
              }

              if (mods.includes(7) || state.usuarioDB.data.role == 'ADMIN') {
                estructura3.children.push({
                    id:7,
                    name: 'Seguimiento proyectos',
                    isChecked: true,
                    link: {name: 'seguimiento'}
                })
              }
              if (mods.includes(10) || state.usuarioDB.data.role == 'ADMIN') {
                estructura3.children.push({
                    id:10,
                    name: 'Calendario Proyectos',
                    isChecked: true,
                    link: {name: 'calendario'}
                })
              }
              estructura2.children.push(estructura3)

              if (mods.includes(8) || state.usuarioDB.data.role == 'ADMIN') {
                estructura2.children.push({
                    id:8,
                    icon: 'mdi-currency-usd',
                    name: 'Facturación',
                    link: {name: 'facturacion'},
                })
              }

              state.itemsMenu.push(estructura2)

            }

            var ArrayAdministracion = [2]
            if (ArrayAdministracion.some(md => mods.includes(md)) || state.usuarioDB.data.role == 'ADMIN') {
              let estructura2 = {
                id:9001,
                sub: 'Administración',
                name: 'Administración',
                children: []
              }

              if (mods.includes(2) || state.usuarioDB.data.role == 'ADMIN') {
                  estructura2.children.push({
                      id:3,
                      name: 'Gestión Usuarios',
                      link: {name: 'usuarios'},
                      icon: 'settings',
                  })
              }

              state.itemsMenu.push(estructura2)
            }


/*
            //Dashboard
            var ArrayDashboard = [1]
            if (ArrayDashboard.some(md => mods.includes(md)) || state.usuarioDB.data.role == 'ADMIN') {
              state.itemsMenu.push({
                    id:9000,
                    title: 'Dashboard',
                    name: 'Dashboard',
                    icon: 'mdi-view-dashboard',
                    link: {name: 'dashboard'},
                    sub: 'Dashboard',
                    grupo: 'dashboard',
                    
                })
            }
            

            var ArrayProyectos = [7,8,9]
            if (ArrayProyectos.some(md => mods.includes(md)) || state.usuarioDB.data.role == 'ADMIN') {
                let estructura2 = {
                    id:9002,
                    name: 'Presupuestos',
                    icon: 'mdi-text-box-multiple',
                    sub: 'Gestión',
                    grupo: 'presupuestos',
                    children: []
                }

                if (mods.includes(7) || state.usuarioDB.data.role == 'ADMIN') {
                    estructura2.children.push({
                        id:4,
                        name: 'Presupuestos',
                        isChecked: true,
                        //link: '/principal/finanzas/cuentas'
                        link: {name: 'presupuestos'}
                    })
                }

                

                state.itemsMenu.push(estructura2)
            } 

            var ArrayProyectos = [4,5,6]
            if (ArrayProyectos.some(md => mods.includes(md)) || state.usuarioDB.data.role == 'ADMIN') {
                let estructura = {
                    id:9003,
                    title: 'Proyectos',
                    name: 'Proyectos',
                    icon: 'mdi-briefcase',
                    grupo: 'proyectos',
                    link: {name: 'proyectos'},
                    children: []
                }
                state.itemsMenu.push(estructura)

                
            } 

            var ArrayProyectos = [10,11,12]
            if (ArrayProyectos.some(md => mods.includes(md)) || state.usuarioDB.data.role == 'ADMIN') {
                let estructura = {
                    id:9004,
                    name: 'Facturación',
                    icon: 'mdi-currency-usd',
                    grupo: 'facturacion',
                    link: {name: 'facturacion'},
                    children: []
                }
                state.itemsMenu.push(estructura)

                
            } 


            var ArrayAdministracion = [2, 3]
            if (ArrayAdministracion.some(md => mods.includes(md)) || state.usuarioDB.data.role == 'ADMIN') {
                let estructura = {
                    id:9001,
                    title: 'Gestión Usuarios',
                    name: 'Gestión Usuarios',
                    icon: 'settings',
                    grupo: 'administracion',
                    sub: 'Administración',
                    link: {name: 'usuarios'},
                    children: []
                }

                state.itemsMenu.push(estructura)

               
              }

            /*var ArrayFinanzas = [4,5,6]
            if (ArrayFinanzas.some(md => mods.includes(md)) || state.usuarioDB.data.role == 'ADMIN') {
                let estructura2 = {
                    id:9002,
                    name: 'Finanzas',
                    icon: 'mdi-finance',
                    grupo: 'finanzas',
                    children: []
                }

                if (mods.includes(4) || state.usuarioDB.data.role == 'ADMIN') {
                    estructura2.children.push({
                        id:4,
                        name: 'Cuentas',
                        isChecked: true,
                        //link: '/principal/finanzas/cuentas'
                        link: {name: 'cuentas'}
                    })
                }

                if (mods.includes(5) || state.usuarioDB.data.role == 'ADMIN') {
                  estructura2.children.push({
                      id:5,
                      name: 'Egresos',
                      isChecked: true,
                      //link: '/principal/finanzas/egresos'
                      link: {name: 'egresos'}
                  })
                }

                if (mods.includes(6) || state.usuarioDB.data.role == 'ADMIN') {
                  estructura2.children.push({
                      id:6,
                      name: 'Ingresos',
                      isChecked: true,
                      //link: '/principal/finanzas/ingresos'
                      link: {name: 'ingresos'}
                  })
                }

                state.itemsMenu.push(estructura2)
            } 


            var ArrayComunicaciones = [7,9]
            if (ArrayComunicaciones.some(md => mods.includes(md)) || state.usuarioDB.data.role == 'ADMIN') {
                let estructura2 = {
                    id:9003,
                    name: 'Comunicaciones',
                    icon: 'mdi-bullhorn',
                    grupo: 'comunicaciones',
                    children: []
                }

                if (mods.includes(7) || state.usuarioDB.data.role == 'ADMIN') {
                    estructura2.children.push({
                        id:7,
                        name: 'Comunicados',
                        isChecked: true,
                        //link: '/:empresa/principal/comunicaciones/comunicados'
                        link: {name: 'comunicados'}
                    })
                }

                if (mods.includes(9) || state.usuarioDB.data.role == 'ADMIN') {
                  estructura2.children.push({
                      id:9,
                      name: 'Consultas',
                      isChecked: true,
                      //link: '/principal/comunicaciones/consultas'
                      link: {name: 'consultas'}
                  })
              }

                state.itemsMenu.push(estructura2)
            } 

            var ArraySecretaria = [8]
            if (ArraySecretaria.some(md => mods.includes(md)) || state.usuarioDB.data.role == 'ADMIN') {
                let estructura2 = {
                    id:9004,
                    name: 'Secretaria',
                    icon: 'mdi-file-document-multiple',
                    grupo: 'secretaria',
                    children: []
                }

                if (mods.includes(8) || state.usuarioDB.data.role == 'ADMIN') {
                    estructura2.children.push({
                        id:8,
                        name: 'Actas de secretaria',
                        isChecked: true,
                        //link: '/principal/secretaria/actas'
                        link: {name: 'actas'}
                    })
                }

                state.itemsMenu.push(estructura2)
            }*/

            

            //console.log(state.itemsMenu)

            
    },
    
    

  },
  actions: {

    async guardarUsuario({dispatch,commit}, payload){
      localStorage.setItem('token', payload);
      commit('obtenerUsuario', payload)
      //await dispatch('traerEmpresas')

      //commit('establecerComunidad', '')
      //console.log("Comunidad seleccionada: "+state.comunidadSeleccionada)
      //router.push({name: 'principal'});
    },
    cerrarSesion({commit}){
      commit('obtenerUsuario', '');
      localStorage.removeItem('token');
      router.push({name: 'login'});
    },
    leerToken({commit}){

      const token = localStorage.getItem('token');
      if(token){
        commit('obtenerUsuario', token);
        commit('Menu','');
        
      }else{
        commit('obtenerUsuario', '');
      }

    },
    
    async AlmacenaUsuario({dispatch,commit}, payload){
      localStorage.setItem('token', payload);
      
      commit('obtenerUsuario', payload)
      
      router.push({name: 'principal'});
    }



  },
  getters:{
    estaActivo: state => !!state.token,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      },
      //paths: ['comunidades']
    })
  ]
})