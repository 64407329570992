import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md', 
    
    },
  theme: {
      options: {
        customProperties: true,
      },
    themes: {
      light: {
        //primary: '#5D5883',
        primary: '#1C67FD',
        primaryaccent:'#E5EEFF',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        purpura0: '#6A1B9A',
        purpura1: '#7B1FA2',
        purpura2: '#9C27B0',
        purpura3: '#E1BEE7',
        purpura4: '#3f1260',
        blanco: '#FFFFFF',
        azulElectrico: '#006CFF',
        azulMovimientosIcons: '#055DA6',
        azulMovimientos: '#0270B9',
        azulTemplate: '#3F6BD8',
        gris: '#607D8B',
        indigo1: '#4051B5',
        indigo2: '#193A99',
        blanco3: '#FAFBFC',
        blanco2: '#F5F6F7',
        blanco4: '#EFF2F4',
        indigo7: '#5D5883',
        indigo8: '#C8C9D6',
        negro: '#232728',
        grisBarraApp: '#FAFBFD'
      },
      dark: {
        primary: '#FFFFFF'
      }
    },
  },
});
